import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface PipelineCreateErrorType {
  pipeline_name?: string,
  description?: string,
}
interface TaskType {
  task_name: string
  label: string
}
interface LevelType {
  level_name: string
  label: string
}
interface UserType {
  user_name: string
  label: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pipeline_name: string;
  description: string;
  permission_level: string;
  errors: PipelineCreateErrorType
  visibleBy: string
  selectedTasks: TaskType[]
  tasks: TaskType[]
  associatedClinic: string
  levels: LevelType[]
  users: UserType[]
  selectedUsers: UserType[]
  selectedLevels: LevelType[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreatePipelineController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createPipelineApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pipeline_name: '',
      description: '',
      permission_level: '',
      errors: {},
      visibleBy: '',
      selectedTasks: [],
      selectedUsers: [],
      selectedLevels: [],
      tasks: [{ task_name: 'Task 1', label: 'Task 1' }, { task_name: 'Task 2', label: 'Task 2' }, { task_name: 'Task 3', label: 'Task 3' }],
      levels: [{ level_name: 'Level 1', label: 'Level 1' }, { level_name: 'Level 2', label: 'Level 2' }, { level_name: 'Level 3', label: 'Level 3' }],
      users: [{ user_name: 'CC 1', label: 'CC 1' }, { user_name: 'CC 2', label: 'CC 2' }, { user_name: 'CC 3', label: 'CC 3' }],
      associatedClinic: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.getPipelineCreatedData(message)
    // Customizable Area End
  }
  // Customizable Area Start

  isFormComplete = () => {
    const { pipeline_name, description, visibleBy, selectedLevels, selectedTasks, selectedUsers, associatedClinic } = this.state;
    return (
      pipeline_name &&
      description &&
      associatedClinic && visibleBy && (selectedLevels.length > 0 || selectedUsers.length > 0 || selectedTasks.length > 0)
    );
  };

  async componentDidMount() {
    super.componentDidMount()
  }

  handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      const updatedErrors = { ...prevState.errors };

      if (name === "pipeline_name" && value.length <= 100) {
        delete updatedErrors.pipeline_name;
      }

      if (name === "description" && value.length <= 300) {
        delete updatedErrors.description;
      }

      return {
        ...prevState,
        [name]: value,
        errors: updatedErrors,
      };
    });
  };
  validatePipelineFields = (pipeline_name: string, description: string) => {
    const errors: PipelineCreateErrorType = {};

    if (pipeline_name.length > 100) {
      errors.pipeline_name = "Pipeline name cannot be more than 100 characters.";
    }

    if (description.length > 300) {
      errors.description = "Pipeline description cannot exceed 300 characters.";
    }

    return errors;
  };

  handleCreatePipeline = (event: any) => {
    event.preventDefault()
    let token = "";
    if (typeof localStorage !== 'undefined') {
      token = localStorage.getItem("token") || "";
    }
    const PCheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const { pipeline_name, description } = this.state
    const pipelineErrors = this.validatePipelineFields(pipeline_name, description);

    if (Object.keys(pipelineErrors).length > 0) {
      this.setState({ errors: pipelineErrors });
      return;
    }
    const httpBody = {
      pipeline: {
        pipeline_name,
        description,
        permission_level: 'level1'
      }
    }
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createPipelineApiId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPipelineApiEndPoint);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  getPipelineCreatedData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.createPipelineApiId) {
        if (responseJson.pipeline_name) {
          this.props.navigation.navigate('Cfstages1', { pipelineId: responseJson.id })
        }
      }
    }
  }
  handleAutoComplete = (name: string) => (event: any, newValue: any) => {
    this.setState((prev) => {
      return {
        ...prev,
        [name]: newValue
      }
    });
  }
  // Customizable Area End
}
