import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from 'react-toastify';
type PipelineResponse = {
  id: string;
  type: string;
  attributes: {
    pipeline_name: string;
    stages: Stage[];
    description: string;
    status: string | null;
    permission_level: string;
  };
};
type Stage = {
  id: number;
  stage_name: string;
  description: string;
  task: any;
  permission_level: string;
  created_at: string;
  updated_at: string;
  pipeline_id: number;
  rule_builder_id: number | null;
};

type PermissionType = {
  label: string,
  value: string
}

type StageErrorType = {
  stage_name?: string
  description?: string
  permission_level?: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  stage_name: string,
  description: string,
  permission_level: string,
  isModalOpen: boolean,
  isEditModalOpen: boolean,
  editStageValue: string,
  filterPipeline: PipelineResponse,
  pipeline_id: string | null,
  stages: Stage[],
  stageId: string,
  permissions: PermissionType[],
  errors: StageErrorType
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfstages1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pipelineApiCallId: string = ''
  createStageApiCallId: string = ''
  editStageApiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      stage_name: '',
      description: '',
      permission_level: '',
      isModalOpen: false,
      isEditModalOpen: false,
      editStageValue: '',
      filterPipeline: { id: '', type: '', attributes: { description: '', permission_level: '', pipeline_name: '', stages: [], status: '' } },
      pipeline_id: '',
      stages: [],
      stageId: '',
      permissions: [{ label: "Level 01", value: "level1" }, { label: "Level 02", value: "level2" }, { label: "Level 03", value: "level3" }],
      errors: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.getSinglePipelineDataValue(message)
    this.getStageCreatedData(message)
    this.getEditedStageDataValue(message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleClose = () => {
    this.toggleModal();
  };

  handleEditClose = () => {
    this.toggleEditModal()
  }

  toggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  toggleEditModal = () => {
    this.setState((prevState) => ({ isEditModalOpen: !prevState.isEditModalOpen }));
  };

  handleAddStage = (event: any) => {
    event.preventDefault()
    let token = "";
    if (typeof localStorage !== 'undefined') {
      token = localStorage.getItem("token") || "";
    }
    const StageHeader = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const { stage_name, description, permission_level, pipeline_id } = this.state
    const addStageErrorMessages: StageErrorType = {};

    if (!stage_name) addStageErrorMessages.stage_name = "Stage name is required.";
    if (!description) addStageErrorMessages.description = "Description is required.";
    if (!permission_level) addStageErrorMessages.permission_level = "Permission Level is required.";

    if (Object.keys(addStageErrorMessages).length > 0) {
      this.setState({ errors: addStageErrorMessages });
      return;
    }
    const httpBody = {
      stage: {
        stage_name,
        description,
        permission_level,
        pipeline_id
      }
    }
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createStageApiCallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stageApiEndPoint);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(StageHeader));

    getAllData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  };

  getStageCreatedData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.createStageApiCallId) {
        if (Array.isArray(responseJson.stage_name)) {
          toast.error(`Stage name ${responseJson.stage_name[0]}`, { style: { fontSize: "1.6rem" } })
        } else {
          this.getAllPipelineData()
          toast.success('Stage created successfully', { style: { fontSize: "1.6rem" } })
        }
        this.setState({ stage_name: '', description: '', permission_level: '' })
        this.toggleModal()
      }
    }
  }
  async componentDidMount() {
    super.componentDidMount()
    this.getPipelineIdFromUrl()
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.pipeline_id !== this.state.pipeline_id) {
      this.getAllPipelineData()
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    } as S));
  };

  handleEditStageToggle = (item: any) => {
    this.toggleEditModal()
    this.setState({ stageId: item.id, editStageValue: item.stage_name })
  }
  handleEditStage = (event: any) => {
    event.preventDefault()
    let token = "";
    if (typeof localStorage !== 'undefined') {
      token = localStorage.getItem("token") || "";
    }
    const StageEditHeader = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const { editStageValue, pipeline_id } = this.state
    const httpBody = {
      stage: {
        stage_name: editStageValue,
        pipeline_id
      }
    }
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editStageApiCallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.stageApiEndPoint}/${this.state.stageId}/`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(StageEditHeader));

    getAllData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethodType
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  getEditedStageDataValue(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId === this.editStageApiCallId) {
        this.setState({ editStageValue: '' })
        this.toggleEditModal()
        this.getAllPipelineData()
      }
    }
  }

  getAllPipelineData = () => {
    let tokenValue = "";
    if (typeof localStorage !== 'undefined') {
      tokenValue = localStorage.getItem("token") || "";
    }
    const PCheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokenValue
    };
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.pipelineApiCallId = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPipelineDataEndPoint}/${this.state.pipeline_id}`);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getAllData.id, getAllData);
  }

  getSinglePipelineDataValue(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.pipelineApiCallId) {
        if (responseJson && responseJson.errors) {
          this.setState({ filterPipeline: { id: '', type: '', attributes: { description: '', permission_level: '', pipeline_name: '', stages: [], status: '' } }, });
        } else if (responseJson && responseJson.data && responseJson.data.attributes) {
          this.setState({
            filterPipeline: responseJson.data,
            stages: responseJson.data.attributes.stages
          });
        } else {
          this.setState({ filterPipeline: { id: '', type: '', attributes: { description: '', permission_level: '', pipeline_name: '', stages: [], status: '' } }, });
        }
      }
    }
  }

  handleCreateRule = () => {
    if (this.props.navigation.navigate) {
      this.props.navigation.navigate('CreatePipelineRule', { pipelineId: this.state.pipeline_id })
    }
  }

  getPipelineIdFromUrl = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const pipeline_id = queryParams.get('pipelineId');
    this.setState({ pipeline_id });
  }
  // Customizable Area End
}
