import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import Pagination from '@mui/material/Pagination';
import { Box, Button as MuiButton, Typography as MuiTypography, TextField, InputAdornment, IconButton, Modal, Divider, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search'
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
// Customizable Area End

import Cfpipelines1Controller, {
  Props,
  configJSON,
} from "./Cfpipelines1Controller";

export default class Cfpipelines1 extends Cfpipelines1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderColumnTypography = (flex: number, text: string | number) => {
    return (
      <MuiTypography variant="h6" style={{ fontSize: '1.4rem', flex: flex, fontWeight: 700, color: '#3E3E3E' }}>{text}</MuiTypography>
    )
  }

  renderRowTypography = (flex: number, text: string | number, id: string | number) => {
    return (
      <MuiTypography variant="h6" data-test-id="row-typography" style={{
        fontSize: '1.4rem',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        flex: flex, fontWeight: 600, cursor: 'pointer', color: '#203447'
      }} onClick={() => this.handlePipelineNavigate(id)}>{text}</MuiTypography>
    )
  }
  renderFromControlLabel = (value: string, label: string) => {
    return <FormControlLabel value={value} control={<Radio />} label={label}
      sx={{
        '& .MuiFormControlLabel-label': {
          fontSize: '1.2rem', fontWeight: 600, color: '#203447'
        },
      }} />
  }
  pipelineList = () => {
    const { currentPage, pageSize, filteredData } = this.state;
    const paginatedRows = filteredData.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    const startIndex = currentPage * pageSize + 1;
    const endIndex = Math.min((currentPage + 1) * pageSize, filteredData.length);
    const totalResults = filteredData.length;
    return <Box style={{ display: "flex", flexDirection: "column" }}>
      <Box style={{ display: "flex", marginBottom: '1.6rem', justifyContent: "space-between", alignItems: 'center', flexWrap: 'nowrap' }}>
        <MuiTypography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>
          Pipelines
        </MuiTypography>

        <Box display='flex' alignItems='center' gap={1}>
          <TextField
            variant="outlined"
            placeholder="Search by client, pipeline, CC....."
            value={this.state.searchQuery}
            onChange={this.handleSearchPipeline}
            inputProps={{
              sx: {
                padding: '0.5rem 4rem 0.5rem 0.6rem',
                fontWeight: 400,
                fontSize: '1.6rem',
                width: '25rem',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fontSize: "2rem", color: "#1A4A42" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              flex: '1', minWidth: '25rem',
              '& .MuiOutlinedInput-root': {
                borderRadius: '0.9rem',
              },
            }}
          />
          <MuiButton onClick={this.handleCreatePipeline} sx={{
            backgroundColor: '#1A4A42',
            color: 'white',
            padding: '0.4rem 1.6rem',
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            fontSize: '1.4rem',
            whiteSpace: 'nowrap',
            '&:hover': {
              backgroundColor: '#1B5C54',
              color: '#fff',
            }
          }}>
            Create Pipeline
          </MuiButton>
          <Box style={{ position: 'relative' }}>
            <FilterListIcon onClick={this.toggleFilterModal} style={{ ...iconStyle, fontSize: "2rem" }} />
            {this.state.isFilterModal &&
              <Box sx={filterModalStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.4rem' }}>Sort Pipeline By</MuiTypography>
                  <IconButton onClick={() => this.toggleFilterModal()}>
                    <CloseIcon />
                  </IconButton>
                </Box>

                <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
                <Box sx={{ display: "flex", flexDirection: 'column' }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ fontSize: '1.2rem', color: '#1A4A42', fontWeight: 500 }}>Select Sort Field</FormLabel>
                    <RadioGroup
                      data-test-id="sort-by-radio"
                      value={this.state.sortBy}
                      onChange={(e) => this.handleSortFieldChange(e, e.target.value)}
                      row
                    >
                      {this.renderFromControlLabel("name", "Name")}
                      {this.renderFromControlLabel("created_at", "Created At")}
                      {this.renderFromControlLabel("updated_at", "Updated At")}
                      {this.renderFromControlLabel("id", "ID")}
                      {this.renderFromControlLabel("asso_clinic", "Associated Clinic")}
                    </RadioGroup>
                  </FormControl>


                  {this.state.sortBy !== "asso_clinic" &&
                    <FormControl component="fieldset">
                      <FormLabel component="legend" style={{ fontSize: '1.2rem', color: '#1A4A42', fontWeight: 500 }}>Sort Direction</FormLabel>
                      <RadioGroup
                        data-test-id="sort-direction-radio"
                        value={this.state.sortDirection}
                        onChange={(e) => this.handleSortDirectionChange(e, e.target.value)}
                        row
                      >
                        {this.renderFromControlLabel("asc", this.getSortLabel('asc'))}
                        {this.renderFromControlLabel("desc", this.getSortLabel('desc'))}
                      </RadioGroup>
                    </FormControl>
                  }
                </Box>
                <Box display="flex" justifyContent="center" gap={2} mt={3}>
                  <MuiButton onClick={this.toggleFilterModal} variant="contained" sx={{
                    backgroundColor: '#1A4A421A',
                    color: '#1A4A4299',
                    padding: '0.5rem 1rem',
                    fontWeight: 700,
                    borderRadius: '0.8rem',
                    textTransform: 'none',
                    fontSize: '1.2rem'
                  }}>
                    Cancel
                  </MuiButton>
                  <MuiButton onClick={this.handleSortChange} variant="contained" sx={{
                    backgroundColor: '#1A4A42',
                    color: 'white',
                    padding: '0.5rem 1rem',
                    fontWeight: 700,
                    borderRadius: '0.8rem',
                    textTransform: 'none',
                    fontSize: '1.2rem'
                  }}>
                    Apply
                  </MuiButton>
                </Box>
              </Box>
            }
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection='column' gap={1}>
        <Box display="flex" style={{ backgroundColor: '#29252414', padding: '0.5rem 1rem', borderRadius: ' 0.8rem' }}>
          {this.renderColumnTypography(3, 'Pipeline ID')}
          {this.renderColumnTypography(5, 'Name')}
          {this.renderColumnTypography(3, 'Date Created')}
          {this.renderColumnTypography(3, 'Last Edited')}
          {this.renderColumnTypography(3, 'Asso. Clinic')}
          {this.renderColumnTypography(2, 'Actions')}
        </Box>
        {paginatedRows.length > 0 ? paginatedRows.map(row => (
          <Box display='flex' key={row.id} style={{ padding: '0.5rem 1rem', borderBottom: '0.1rem solid #1A4A421A', alignItems: 'center' }}>
            {this.renderRowTypography(3, row.id, row.id)}
            {this.renderRowTypography(5, row.pipeline_name, row.id)}
            {this.renderRowTypography(3, this.formatDate(row.created_at), row.id)}
            {this.renderRowTypography(3, this.formatDate(row.updated_at), row.id)}
            {this.renderRowTypography(3, row.clinic_name, row.id)}
            <Box style={{ flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <IconButton data-test-id="duplicate-btn" sx={addButtonStyle} style={{ padding: "0.6rem" }} onClick={() => this.handleDuplicateToggle(row)}>
                <ControlPointDuplicateIcon />
              </IconButton>
              <IconButton data-test-id="edit-btn" sx={addButtonStyle} style={{ padding: "0.6rem" }} onClick={() => this.handlePipelineNavigate(row.id)}>
                <EditIcon />
              </IconButton>
              <IconButton data-test-id="delete-btn" sx={deleteIconStyle} style={{ padding: "0.6rem" }} onClick={() => this.handleDeleteToggle(row.id, row.pipeline_name)}>
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          </Box>
        )) : <Box display='flex' justifyContent='center' alignItems='center'><MuiTypography variant="h5">No Pipelines Found</MuiTypography></Box>}
      </Box>

      <Box style={{ display: 'flex', alignItems: "center", marginTop: '0.8rem', paddingTop: "0.5rem", justifyContent: 'space-between', fontFamily: "Outfit" }}>
        <Box style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={Math.ceil(totalResults / pageSize)}
            page={currentPage + 1}
            onChange={this.handlePageChange}
            color="standard"
            sx={{
              '& .css-7s3al-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
                borderRadius: '0.8rem',
                color: '#1A4A42',
                fontWeight: 700
              },
              '& .css-7s3al-MuiButtonBase-root-MuiPaginationItem-root': {
                fontSize: '1.4rem',
                fontFamily: 'Outfit',
                fontWeight: 400,
                minWidth: '2.6rem',
                height: '2.4rem',
                color: '#1A4A424D'
              },
              '& .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon': {
                width: '2em',
                height: '2em',
                fill: '#292524'
              }
            }}
            style={{ marginLeft: '2rem', borderRadius: "0", fontSize: '1.6rem' }}
          />
        </Box>
        <span style={{ margin: 0, fontSize: "1.2rem", fontWeight: 300, fontFamily: 'Outfit' }}>{`${startIndex} - ${endIndex}`}<span style={{ fontFamily: "Raleway", fontWeight: 400 }}> of </span>{`${totalResults} results`}</span>
      </Box>
    </Box>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={pipelineListContainerStyle}>
        <TopNav navigation={this.props.navigation} id="pipelines" />
        <Divider sx={dividerStyle} />
        {this.pipelineList()}
        <Modal
          data-test-id="delete-pipeline-modal"
          open={this.state.isDeletePipelineModal}
          onClose={this.toggleDeleteModal}
          aria-labelledby="edit-stage-modal"
          aria-describedby="edit-stage-form"
        >
          <Box sx={modalStyle}>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524" }}>Delete</MuiTypography>
              <IconButton onClick={this.toggleDeleteModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <MuiTypography variant="h6" style={{ fontSize: '1.2rem' }}>{`Are you sure want to delete pipeline: ${this.state.pipeline_name} ?`}</MuiTypography>
            </Box>
            <Box display="flex" justifyContent="center" gap={2} mt={3}>
              <MuiButton onClick={this.toggleDeleteModal} variant="contained" sx={{
                backgroundColor: '#1A4A421A',
                color: '#1A4A4299',
                padding: '0.8rem 0',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                width: "12rem"
              }}>
                Cancel
              </MuiButton>
              <MuiButton onClick={this.handleDeletePipeline} variant="contained" sx={{
                backgroundColor: '#1A4A42',
                color: 'white',
                padding: '0.8rem 0',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                width: "12rem"
              }}>
                Delete
              </MuiButton>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.isPipelineDuplicateModal}
          onClose={this.toggleDuplicateModal}
          aria-labelledby="edit-stage-modal"
          aria-describedby="edit-stage-form"
        >
          <Box sx={modalStyle}>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524" }}>Duplicate Pipeline</MuiTypography>
              <IconButton onClick={this.toggleDuplicateModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <MuiTypography variant="h6" style={{ fontSize: '1.2rem' }}>{`Are you sure want to duplicate pipeline: ${this.state.singlePiplineDuplicate.pipeline_name} ?`}</MuiTypography>
            </Box>
            <Box display="flex" justifyContent="center" gap={2} mt={3}>
              <MuiButton onClick={this.toggleDuplicateModal} variant="contained" sx={{
                backgroundColor: '#1A4A421A',
                color: '#1A4A4299',
                padding: '0.8rem 0',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                width: "12rem",
                fontSize: '1.2rem'
              }}>
                Cancel
              </MuiButton>
              <MuiButton onClick={this.handleDuplicatePipeline} variant="contained" sx={{
                backgroundColor: '#1A4A42',
                color: 'white',
                padding: '0.8rem 0',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                width: "12rem",
                fontSize: '1.2rem'
              }}>
                Duplicate
              </MuiButton>
            </Box>
          </Box>
        </Modal>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const pipelineListContainerStyle = {
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  boxSizing: "border-box",
};
const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};
const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#064E3B",
  padding: "0.6rem",
  borderRadius: "0.6rem",
};

const addButtonStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "1rem",
  borderRadius: "0.8rem",
};
const deleteIconStyle = {
  backgroundColor: "#EF444433",
  color: "#DC2626",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  p: 3,
};
const filterModalStyle = {
  position: 'absolute',
  top: '100%',
  right: '10%',
  width: '25rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  zIndex: '99',
  p: 3,
}
// Customizable Area End
