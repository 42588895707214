import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import TopNav from "../../navigationmenu/src/TopNav.web";
import AddIcon from '@mui/icons-material/Add';
import { infoIcon } from "./assets";
import { Modal, Box, TextField, IconButton, MenuItem, Button as MuiButton, Typography as MuiTypography, Divider, Radio, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
// Customizable Area End

import Cfstages1Controller, {
  Props,
  configJSON,
} from "./Cfstages1Controller";

export default class Cfstages1 extends Cfstages1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={pipelineContainerStyle}>
        <TopNav navigation={this.props.navigation} id="pipelines" />
        <Divider sx={dividerStyle} />
        <Box>
          <Box display="flex" justifyContent="space-between" marginBottom={2}>
            <MuiTypography variant="h6" sx={{ fontWeight: 700, fontSize: '2rem' }}>{this.state.filterPipeline.attributes.pipeline_name}</MuiTypography>
            <MuiButton sx={{
              backgroundColor: '#1A4A42',
              color: 'white',
              padding: '0.4rem 1.6rem',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.4rem',
              width: 'auto'
            }}>All Rules</MuiButton>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: 'center' }}>
            {this.state.stages.length > 0 && this.state.stages.map((item: any) => (
              <Box
                key={item.stage_name}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                sx={stageCardStyle}
              >
                <Box textAlign="center" sx={{ minHeight: "20%" }} display="flex" flexDirection="column" gap={2}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "center" }}>
                    <MuiTypography variant="h6" sx={{ fontSize: '1.6rem', fontWeight: 700 }}>{item.stage_name}</MuiTypography>
                    <IconButton data-test-id="edit-stage-button" onClick={() => this.handleEditStageToggle(item)} sx={addButtonStyle} style={{ padding: "0.4rem" }}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <MuiTypography sx={{ fontWeight: 400, fontSize: '1.4rem' }}>{item.description}</MuiTypography>
                </Box>
                <Box sx={ruleBoxStyle}>
                  <img src={infoIcon.default} alt="info" style={{ height: '4.5rem', width: '4.5rem' }} />
                  <MuiTypography variant="h5" sx={{ margin: "1rem 0 0.5rem 0", fontSize: '1.6rem', fontWeight: 700 }}>Set Rules</MuiTypography>
                  <MuiTypography sx={{ textAlign: 'center', margin: "0.5rem 0 1rem 0", fontWeight: 400, fontSize: '1.3rem' }}>
                    Create or Apply rule to determine how tasks are created in this pipeline
                  </MuiTypography>
                  <MuiButton variant="contained" sx={{
                    marginBottom: "1rem", backgroundColor: '#1A4A42',
                    color: 'white',
                    padding: '0.4rem 1.8rem',
                    fontWeight: 600,
                    borderRadius: '0.8rem',
                    fontSize: "1.4rem",
                    textTransform: 'none',
                  }} data-test-id="create-rule-btn" onClick={this.handleCreateRule}>Create Rule</MuiButton>
                  <MuiButton variant="contained" sx={{
                    backgroundColor: 'white',
                    color: '#1A4A42',
                    padding: '0.4rem 1.8rem',
                    fontWeight: 600,
                    fontSize: "1.4rem",
                    borderRadius: '0.8rem',
                    textTransform: 'none',
                  }}>Apply Rule</MuiButton>
                </Box>
              </Box>
            ))}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              sx={stageCardStyle}
            >
              <Box textAlign="center" sx={{ minHeight: "20%" }}>
                <MuiTypography variant="h6" sx={{ fontWeight: 700, fontSize: '1.6rem' }}>Add New Stage</MuiTypography>
              </Box>
              <Box sx={addStageStyle}>
                <MuiTypography variant="h5" sx={{ marginBottom: "1rem", fontSize: '1.6rem', fontWeight: 700 }}>New Stage</MuiTypography>
                <MuiTypography sx={{ textAlign: 'center', marginBottom: "1rem", fontWeight: 400, fontSize: '1.3rem' }}>Add new stage to the existing pipeline</MuiTypography>
                <IconButton sx={addButtonStyle} onClick={this.toggleModal}>
                  <AddIcon style={{ height: "2.5rem", width: '2.5rem' }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>

        <Modal
          open={this.state.isModalOpen}
          onClose={this.handleClose}
          aria-labelledby="add-stage-modal"
          aria-describedby="add-stage-form"
        >
          <Box sx={modalStyle}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.6rem' }}>Add New Stage</MuiTypography>
              <IconButton onClick={this.handleClose}>
                <CloseIcon style={{ height: "2rem", width: '2rem' }} />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />

            <Box component="form" noValidate autoComplete="off" onSubmit={this.handleAddStage}>
              <MuiTypography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.2rem' }}>
                Stage Name <span style={{ color: "#203447", fontWeight: 400, fontSize: '1rem' }}>(max 50 characters)</span>
              </MuiTypography>
              <TextField
                name="stage_name"
                data-test-id="stage-name"
                value={this.state.stage_name}
                onChange={this.handleChange}
                fullWidth
                InputLabelProps={{ shrink: false }}
                placeholder="Type Stage Name"
                style={{ marginBottom: "0.8rem" }}
                inputProps={{
                  sx: {
                    padding: '1rem 0.8rem',
                    fontWeight: 400,
                    fontSize: '1.6rem'
                  },
                }}
              />
              {this.state.errors.stage_name && (
                <MuiTypography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{this.state.errors.stage_name}</MuiTypography>
              )}


              <MuiTypography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
                Description <span style={{ color: "#203447", fontWeight: 400, fontSize: '1rem' }}>(max 50 characters)</span>
              </MuiTypography>
              <TextField
                name="description"
                data-test-id="stage-desc"
                value={this.state.description}
                onChange={this.handleChange}
                fullWidth
                InputLabelProps={{ shrink: false }}
                placeholder="Type Stage Description"
                style={{ marginBottom: "0.8rem" }}
                inputProps={{
                  sx: {
                    padding: '1rem 0.8rem',
                    fontWeight: 400,
                    fontSize: '1.6rem'
                  },
                }}
              />
              {this.state.errors.description && (
                <MuiTypography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{this.state.errors.description}</MuiTypography>
              )}

              <MuiTypography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: "1.4rem" }}>
                Select Permission
              </MuiTypography>
              <TextField
                name="permission_level"
                data-test-id="select-task"
                value={this.state.permission_level || ''}
                onChange={this.handleChange}
                sx={{ marginBottom: '0.8rem' }}
                select
                fullWidth
                inputProps={{
                  sx: {
                    padding: '1rem 0.8rem',
                    fontSize: '1.6rem',
                    fontWeight: 400
                  },
                }}
                InputLabelProps={{ shrink: false }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (selected: unknown) => {
                    if (!selected) {
                      return <span style={{ color: "#999" }}>Search Task</span>;
                    }
                    return selected as string;
                  },
                }}
              >
                {this.state.permissions.map((permissionItem, index) => (
                  <MenuItem key={index} value={permissionItem.value}
                    sx={{
                      fontSize: '1.6rem', fontWeight: 400, backgroundColor: this.state.permission_level === permissionItem.value ? '#1A4A421A' : 'white',
                      '&:hover': {
                        backgroundColor: this.state.permission_level === permissionItem.value ? '#1A4A421A' : '#f0f0f0',
                      },
                    }}
                  >
                    <FormControlLabel
                      value={permissionItem.value}
                      control={
                        <Radio
                          checked={this.state.permission_level === permissionItem.value}
                          onChange={this.handleChange}
                          value={permissionItem.value}
                          name="permission_level"
                          sx={{
                            padding: '0 0.8rem',
                            '&.Mui-checked': {
                              color: '#1A4A42',
                            },
                            '&.MuiRadio-root': {
                              color: '#1A4A42',
                            },
                            '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                              backgroundColor: '#1A4A421A',
                            },
                          }}
                        />
                      }
                      label={<MuiTypography sx={{ fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>
                        {permissionItem.label}
                      </MuiTypography>}
                      sx={{
                        fontSize: '1.6rem',
                        fontWeight: '400'
                      }}
                    />
                  </MenuItem>
                ))}
              </TextField>
              {this.state.errors.permission_level && (
                <MuiTypography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{this.state.errors.permission_level}</MuiTypography>
              )}

              <Box display="flex" justifyContent="center" gap={2} mt={3}>
                <MuiButton onClick={this.handleClose} variant="contained" sx={{
                  backgroundColor: '#1A4A421A',
                  color: '#1A4A4299',
                  padding: '0.8rem 0',
                  fontWeight: 700,
                  borderRadius: '0.8rem',
                  textTransform: 'none',
                  width: "12rem",
                  fontSize: '1.2rem'
                }}>
                  Close
                </MuiButton>
                <MuiButton type="submit" variant="contained" sx={{
                  backgroundColor: '#1A4A42',
                  color: 'white',
                  padding: '0.8rem 0',
                  fontWeight: 700,
                  borderRadius: '0.8rem',
                  textTransform: 'none',
                  width: "12rem",
                  fontSize: '1.2rem'
                }}>
                  Add
                </MuiButton>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={this.state.isEditModalOpen}
          onClose={this.handleEditClose}
          aria-labelledby="edit-stage-modal"
          aria-describedby="edit-stage-form"
        >
          <Box sx={modalStyle}>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524" }}>Rename</MuiTypography>
              <IconButton onClick={this.handleEditClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
            <Box component="form" noValidate autoComplete="off" onSubmit={this.handleEditStage}>
              <MuiTypography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700 }}>
                Edit Stage Name
              </MuiTypography>
              <TextField
                name="editStageValue"
                value={this.state.editStageValue}
                onChange={this.handleChange}
                fullWidth
                InputLabelProps={{ shrink: false }}
                placeholder="Type Stage Name"
                style={{ marginBottom: "0.8rem" }}
                inputProps={{
                  sx: {
                    padding: '1rem 0.8rem'
                  },
                }}
              />
              <Box display="flex" justifyContent="center" gap={2} mt={3}>
                <MuiButton onClick={this.handleEditClose} variant="contained" sx={{
                  backgroundColor: '#1A4A421A',
                  color: '#1A4A4299',
                  padding: '0.8rem 0',
                  fontWeight: 700,
                  borderRadius: '0.8rem',
                  textTransform: 'none',
                  width: "12rem"
                }}>
                  Cancel
                </MuiButton>
                <MuiButton type="submit" variant="contained" sx={{
                  backgroundColor: '#1A4A42',
                  color: 'white',
                  padding: '0.8rem 0',
                  fontWeight: 700,
                  borderRadius: '0.8rem',
                  textTransform: 'none',
                  width: "12rem"
                }}>
                  Save
                </MuiButton>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const pipelineContainerStyle = {
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  boxSizing: "border-box",
};

const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};

const stageCardStyle = {
  maxWidth: '20rem',
  height: '45rem',
  margin: '1rem',
  flexBasis: '20rem',
};

const ruleBoxStyle = {
  borderRadius: "1.2rem",
  padding: '0 1.5rem',
  backgroundColor: "#1A4A424D",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '80%',
  justifyContent: "center"
};

const addStageStyle = {
  borderRadius: "1.2rem",
  padding: '0 1.5rem',
  backgroundColor: "#1A4A421A",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '80%',
  justifyContent: "center"
}

const addButtonStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "1rem",
  borderRadius: "0.8rem",
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  p: 3,
};


// Customizable Area End
