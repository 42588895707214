import { v4 as uuidv4 } from 'uuid';
export interface PipelineType {
  id: string | number,
  pipeline_name: string,
  description: string,
  created_at: string,
  updated_at: string,
  permission_level: string,
  pipeline_serial_id: string,
  status: null | string,
  order: null,
  stages: StagesDataType[],
  clinic_name: string,
  visibleBy: VisibleBy
  rules: RulesType[]
}
interface VisibleBy {
  PermissionLevels: LevelType[]
  TaskGroups: TaskGroupType[]
  IndividualUsers: UserType[]
}
interface StagesDataType {
  id: string,
  stage_name: string,
  description: string,
  task: null,
  permission_level: string,
  created_at: string,
  updated_at: string,
  pipeline_id: number,
  rule_builder_id: null | number
  deals: DealType[]
}
interface LevelType {
  level_name: string
}
interface TaskGroupType {
  task_name: string
}
interface UserType { 
  user_name: string, 
  source: string 
}
interface RulesType {
  rule_name: string
  rule_desc: string
}
interface DealType {
  deal_name: string,
    deal_id: string,
    deal_desc: string
}

const generateCustomPipelineId = (index: number) => {
  return `#P${(index + 1).toString().padStart(8, '0')}`;
};
const generateCustomStageId = (index: number) => {
  return `#S${(index + 1).toString().padStart(6, '0')}`;
};
const generateCustomDealId = (index: number) => {
  return `D${(index + 1).toString().padStart(8, '0')}`;
};
const americanNames = [
  "John Smith", "Michael Johnson", "James Brown", "Robert Williams", "David Jones", 
  "Mary Miller", "Jennifer Davis", "Patricia Garcia", "Linda Martinez", "Barbara Wilson"
];
const getRandomAmericanName = () => {
  const index = uuidv4().charCodeAt(0) % americanNames.length;
  return americanNames[index];
};
const getRandomInt = (min:number, max:number) => {
  const randomValue = uuidv4().charCodeAt(0); // Use char code as a pseudo-random integer
  return min + (randomValue % (max - min + 1));
};
const generateRandomRules = () => {
  const ruleDescriptions = [
    "This rule contains all deals for users whose birthday is not today.",
    "This rule applies to users who have logged in within the past 30 days.",
    "This rule applies to users who have completed their profile setup.",
    "This rule is active for users in the 'Premium' membership tier.",
    "This rule targets users who have made a purchase in the last 6 months."
  ];

  const ruleCount = getRandomInt(1, 3);
  return Array.from({ length: ruleCount }, (_, index) => ({
    rule_name: `Rule ${index + 1}`,
    rule_desc: ruleDescriptions[index % ruleDescriptions.length]
  }));
};

const generateRandomAvatar = () => {
  const gender = uuidv4().charCodeAt(0) % 2 === 0 ? 'men' : 'women'; // Alternate gender based on char code
  const avatarId = getRandomInt(0, 99); // Random ID within range
  return `https://randomuser.me/api/portraits/${gender}/${avatarId}.jpg`;
};

const generateDescription = (pipelineName: string) => {
  const baseDescription = `This is a detailed description of ${pipelineName}. It includes information on stages, assigned levels, clinic affiliations, and specific tasks or objectives related to this pipeline.`;
  const additionalText = ` The ${pipelineName} is crucial for managing resources efficiently and ensuring that tasks are completed according to specified requirements. Its success is fundamental to achieving key objectives.`;

  // Ensure description length is capped at 300 characters
  return (baseDescription + additionalText).slice(0, 300);
};

const generateRandomDeals = () => {
  const dealCount = getRandomInt(4, 20);
  return Array.from({ length: dealCount }, (_, index) => ({
    deal_name: `Deal ${index + 1}`,
    deal_id: generateCustomDealId(index),
    deal_desc: `This is the description for Deal ${index + 1}`
  }));
};

export const generatePipeline = (count = 36) => {
  const levels = ['Level 1', 'Level 2', 'Level 3', 'Level 4'];
  const clinicNames = ['Clinic 1', 'Clinic 2', 'Clinic 3', 'Global'];
  const stagesNames = ['Stage 1', 'Stage 2', 'Stage 3', 'Stage 4'];
  const randomPipelines = [];

  for (let i = 0; i < count; i++) {
    const randomId = generateCustomPipelineId(i);
    const pipelineName = `Pipeline ${i + 1}`;
    const description = generateDescription(pipelineName);
    const permissionLevel = levels[i % levels.length];
    const clinicName = clinicNames[i % clinicNames.length];
    const createdAt = new Date(Date.now() - getRandomInt(0, 10000000000)).toISOString();
    const updatedAt = new Date(Date.now() - getRandomInt(0, 10000000000)).toISOString();

    // Set a minimum of 6 stages for each pipeline
    const stageCount = Math.max(getRandomInt(6, 10), 6);

    const stages = [];
    for (let j = 0; j < stageCount; j++) {
      const stageName = stagesNames[j % stagesNames.length];
      const stage = {
        id: generateCustomStageId(j),
        stage_name: stageName,
        description: `Description for ${stageName}`,
        task: null,
        permission_level: levels[j % levels.length],
        created_at: new Date(Date.now() - getRandomInt(0, 5000000000)).toISOString(),
        updated_at: new Date(Date.now() - getRandomInt(0, 5000000000)).toISOString(),
        pipeline_id: i + 1,
        rule_builder_id: null,
        deals: generateRandomDeals(),
      };
      stages.push(stage);
    }

    const visibleBy = {
      PermissionLevels: levels.slice(0, getRandomInt(1, 4)).map(level => ({ level_name: level })),
      TaskGroups: Array.from({ length: getRandomInt(3, 6) }, (_, index) => ({
        task_name: `Task group ${index + 1}`,
      })),
      IndividualUsers: Array.from({ length: getRandomInt(2, 6) }, () => ({
        user_name: getRandomAmericanName(),
        source: generateRandomAvatar(),
      })),
    };

    const pipeline = {
      id: randomId,
      pipeline_name: pipelineName,
      description: description,
      order: null,
      pipeline_serial_id: (i + 1).toString(),
      status: null,
      permission_level: permissionLevel,
      created_at: createdAt,
      updated_at: updatedAt,
      stages: stages,
      clinic_name: clinicName,
      visibleBy: visibleBy,
      rules: generateRandomRules(),
    };

    randomPipelines.push(pipeline);
  }

  return randomPipelines;
};

